const links = {
    home: '/',
    teachers: '/teachers',
    courses: '/courses',
    pricing: '/pricing',

    login: '/login',
    register: '/signup',
    forgotPassword: '/forgot-password',

    logout: '/logout',

    userprofile: '/profile',
    upcomingLessons: '/upcoming-lessons',
    lessonHistories: '/lesson-histories',
    profileEdit: '/profile/edit',
    addCredit:'/addCredit',

    schedule: '/schedule',

    teacherProfile: '/teacher/profile',
    teacherProfileEdit: '/teacher/profile/edit',

    lecturerManagement: '/manage/lecturers',
    createLecturer: '/manage/lecturers/create',
    studentManagement: '/manage/students',
    lessonManagement: '/manage/lessons',
    courseManagement: '/manage/courses',
    teachingManagement: '/manage/teachings',
}

export default function useLinks() {
    return links
}
