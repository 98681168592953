import { usePageContext } from "../../lib/page-context";
import image from "next/image";
import { useNewAPICall } from "../index";

export default function useProfileAPI() {
    const [ctx] = usePageContext()
    const client = ctx.httpClients.external

    return async function () {
        let [res, error] = await client({
            url: '/v1/user/profile',
            method: 'GET'
        })

        return error ? null : res.data
    }
}

export function useGetProfileData() {
    let [, setCtx] = usePageContext()
    let profileAPI = useProfileAPI()
    const newAPICall = useNewAPICall()
    return () => {
        let remove = newAPICall();
        profileAPI().then(user => {
            remove();
            if (!user) return

            setCtx((ctx) => ({
                ...ctx,
                userProfile: {
                    ...ctx.userProfile,
                    ...user
                }
            }))
        })
    }
}

export function useUpdateProfileData() {
    const [ctx] = usePageContext()
    const client = ctx.httpClients.external
    const newAPICall = useNewAPICall()

    return async function (data, setApiError) {
        let remove = newAPICall();
        let [res, error] = await client({
            url: '/v1/user/profile',
            method: 'PATCH',
            data
        })
        remove();
        if (error) {
            setApiError(error.response.data.message);
        }
        return error ? error : res
    }
}

export function dataURItoBlob(dataURI) {

    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    let mimeString = dataURI?.split(',')[0]?.split(':')[1]?.split(';')[0];

    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}

export function usePrepareProfileImage() {

    return function (data) {
        let formData = new FormData();
        let file = dataURItoBlob(data);
        formData.append('image', file);

        return { data: formData, size: file.size }
    }
}

export function useUploadProfileImage() {
    const [ctx] = usePageContext()
    const client = ctx.httpClients.external
    const newAPICall = useNewAPICall()

    return async function (imageData) {
        let remove = newAPICall();
        let { data, size } = imageData;
        let [res, error] = await client({
            url: '/v1/user/profile/image',
            method: 'POST',
            headers: {
                'Content-Type': `multipart/form-data; boundary=${size}`,
            },
            data
        })
        remove();
        return error ? error : res
    }
}

export function useUploadProfileImageWithUserId() {
    const [ctx] = usePageContext()
    const client = ctx.httpClients.external
    const newAPICall = useNewAPICall()

    return async function (imageData, userId) {
        let remove = newAPICall();
        let { data, size } = imageData;
        let [res, error] = await client({
            url: `/v1/users/${userId}/profile/image`,
            method: 'POST',
            headers: {
                'Content-Type': `multipart/form-data; boundary=${size}`,
            },
            data
        })
        remove();
        return error ? error : res
    }
}