import axios from "axios";
import {PageContextProvider, usePageContext} from "../lib/page-context";
import uuid from "../lib/uuid";
import NavBar from "../lib/components/nav-bar";
import React from "react";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";

export function useHTTPClients(bearerToken) {
    let external = axios.create({
        baseURL: process.env.NEXT_PUBLIC_API_URL,
        headers: {
            Authorization: 'Bearer ' + bearerToken
        }
    })

    let internal = axios.create({})

    const wrapper = client => async config => {
        try {
            return [await client(config), null]
        } catch(e) {
            return [null, e]
        }
    }

    return {
        external: wrapper(external),
        internal: wrapper(internal)
    }
}

export function useNewAPICall() {
    let [,setCtx] = usePageContext()

    return () => {
        let id = uuid()

        setCtx((prev, _) => {
            return {
                ...prev,
                apiCalls: [
                    ...prev.apiCalls,
                    id
                ]
            }
        })

        return _ => setCtx((prev, _) => {
            let apiCalls = prev.apiCalls
            let idx = apiCalls.indexOf(id)

            if (idx >= 0) {
                apiCalls.splice(idx, 1)

                return {
                    ...prev,
                    apiCalls
                }
            }

            return prev
        })
    }
}

export const withAPI = (Component, withNavBar = true) => ({bearerToken, locale, ...props}) => {
    const initialPageContext = {
        locale,
        apiCalls: [],
        userProfile: null,
        isLoggedIn:!!bearerToken,
        httpClients: useHTTPClients(bearerToken)
    }

    return <PageContextProvider value={initialPageContext}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            { withNavBar && <NavBar isLoggedIn={!!bearerToken} locale={locale}/> }
            <Component {...props} />
        </MuiPickersUtilsProvider>
    </PageContextProvider>
}
