import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import css from './nav-bar.module.css'
import Image from 'next/image'
import { NavItem } from "./nav-item";
import { useNavItems } from "./use-nav-items";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Button from "../button";
import useLinks from "../../../vars/links";
import useImages from "../../../vars/images";
import ProfileMenu, { ProfileImage } from "../profile-menu";
import clsx from "clsx";
import { usePageContext } from "../../page-context";
import { useGetProfileData } from "../../../data/apis/profile";
import Text from '../text';
import {Icon} from "../icon";
import {useUpdateUser} from "../../../data/apis/update-user";

export default function NavBar({
    isLoggedIn = false,
    locale
}) {
    const router = useRouter()
    const { t } = useTranslation('navbar')
    const images = useImages()
    const links = useLinks()
    const [ctx] = usePageContext()
    const getProfileData = useGetProfileData()
    const navItems = useNavItems(t, ctx)

    const [showMenu, setMenu] = useState(false)
    const profileMenuClasses = clsx(
        {
            [css.profileMenuVisible]: showMenu,
            [css.profileMenuAnimateOpen]: showMenu,
        },
        css.profileMenu
    )

    useEffect(() => {
        getProfileData()
    }, [])

    let profileMenuData = useMemo(() => ({
        profileImage: ctx.userProfile?.profile?.profileImage,
        name: ctx.userProfile?.profile?.names?.[locale === 'en' ? 'english' : 'japanese'] || ctx.userProfile?.email?.split('@')[0],
        email: ctx.userProfile?.email,
        userType: ctx.userProfile?.userType || 'student'
    }), [ctx.userProfile])

    let menuItemClicked = useCallback(() => {
        setMenu(false)
    }, [setMenu])

    return (
        <div className={css.root}>
            <div className={css.logoContainer}>
                <Image
                    src={images.logo} alt="One Japanese"
                    width={167}
                    height={48}
                />
                {
                    profileMenuData.userType === 'staff' &&
                    <Text locale={locale} variant='body'>{t('manager')}</Text>
                }
            </div>

            <div className={css['nav-items-and-buttons']}>
                <div className={css.navs}>
                    {navItems.map((v, i) =>
                        <NavItem key={i} href={v.href} active={router.pathname === v.href}>{v.text}</NavItem>
                    )}
                </div>

                {
                    isLoggedIn ?
                        <ProfileImage onClick={_ => setMenu(!showMenu)} profileImage={profileMenuData.profileImage} className={css.profileImg} /> :
                        <div className={css.buttons}>
                            <Button variant="secondary" href={links.login} >{t('login')}</Button>
                            <Button variant="primary" href={links.register} >{t('register')}</Button>
                        </div>
                }

                <Locale locale={locale} />
            </div>

            {showMenu && <div onClick={_ => setMenu(false)} className={css.overlay} />}

            <ProfileMenu
                onMenuItemClick={menuItemClicked}
                className={profileMenuClasses}
                profileImage={profileMenuData.profileImage}
                locale={locale}
                name={profileMenuData.name}
                email={profileMenuData.email}
                userType={profileMenuData.userType}
            />
        </div>
    )
}

function Locale({locale}) {
    const [showMenu, setShowMenu] = useState(false)
    let updateUser = useUpdateUser()

    const showLocaleMenu = () => {
        setShowMenu(true)
    }

    const onLocaleChoose = (locale) => {
        updateUser({ locale }, () => {})
            .then(result => {
                if (result.status === 200) {
                    document.location.reload()
                } else {
                    document.location.href = document.location.pathname + '?locale=' + locale
                }
            })
        setShowMenu(false)
    }

    return (
        <div className={css.locale}>
            <Text onClick={showLocaleMenu} variant={'body'} color={'grey700'}>{locale.toUpperCase()}</Text>
            <div onClick={showLocaleMenu}><Icon variant={'arrow-down'} color={'grey700'}/></div>

            {
                showMenu &&
                <LocaleMenu onClick={onLocaleChoose} dismiss={_ => setShowMenu(false)} />
            }
        </div>
    )
}

function LocaleMenu({onClick, dismiss}) {
    useEffect(() => {
        let dismissListener = () => {
            dismiss()
        }

        document.addEventListener('click', dismissListener)

        return () => {
            document.removeEventListener('click', dismissListener)
        }
    }, [])

    const onItemClick = (e, locale) => {
        e.stopPropagation()
        onClick(locale)
    }

    return (
        <div className={css.localeMenu} >
            <div onClick={e => onItemClick(e, 'en')}>
                <Text variant={'body'} color={'grey900'}>English</Text>
            </div>
            <div onClick={e => onItemClick(e, 'jp')}>
                <Text variant={'body'} color={'grey900'}>日本語</Text>
            </div>
        </div>
    )
}