import {usePageContext} from "../../lib/page-context";
import {useNewAPICall} from "../index";

export function useUpdateUser(){
    const [ctx] = usePageContext()
    const client = ctx.httpClients.external
    const newAPICall = useNewAPICall()

    return async function (data,setError) {
        let remove =newAPICall();
        let [res, error] = await client({
            url: '/v1/user',
            method: 'PATCH',
            data
        })
        remove();
        if(error){
            setError(error.response.data.message)
        }
        return error ? error : res
    }
}

export function useUpdateUserWithUserId(){
    const [ctx] = usePageContext()
    const client = ctx.httpClients.external
    const newAPICall = useNewAPICall()

    return async function (data, userId, setError) {
        let remove =newAPICall();
        let [res, error] = await client({
            url: `/v1/users/${userId}`,
            method: 'PATCH',
            data
        })
        remove();
        if(error){
            setError(error.response.data.message)
        }
        return error ? error : res
    }
}

export function useUpdateUserEmail(){

    const [ctx] = usePageContext()
    const client = ctx.httpClients.external
    const newAPICall = useNewAPICall()

    return async function (data,setError) {
        let remove =newAPICall();
        let [res, error] = await client({
            url: '/v1/user/email',
            method: 'PATCH',
            data
        })
        remove();
        if(error){
            setError(error.response.data.message)
        }
        return error ? error : res
    }
}

export function useUpdateUserPassword(){

    const [ctx] = usePageContext()
    const client = ctx.httpClients.external
    const newAPICall = useNewAPICall()

    return async function (data,setError) {
        let remove =newAPICall();
        let [res, error] = await client({
            url: '/v1/user/password',
            method: 'PATCH',
            data
        })
        remove();
        if(error){
            setError(error.response.data.message)
        }
        return error ? error : res
    }
}
