import useLinks from "../../../vars/links";
import { useMemo } from "react";

export function useNavItems(t, ctx) {
    const links = useLinks()

    const defaultNavLinks = [
        {
            text: t('teachers'),
            href: links.teachers,
        },

        {
            text: t('courses'),
            href: links.courses
        }
    ]

    const studentNavLinks = [
        // {
        //     text: t('home'),
        //     href: links.home
        // },

        {
            text: t('teachers'),
            href: links.teachers,
        },

        {
            text: t('courses'),
            href: links.courses
        }

        // {
        //     text: t('pricing'),
        //     href: links.pricing
        // }
    ]

    const teacherNavLinks = [
        {
            text: t('schedule'),
            href: links.schedule
        }
    ]

    const staffNavLinks = [
        {
            text: t('lecturer-management'),
            href: links.lecturerManagement
        },
        {
            text: t('student-management'),
            href: links.studentManagement
        },
        {
            text: t('lesson-management'),
            href: links.lessonManagement
        },
        {
            text: t('course-management'),
            href: links.courseManagement
        },
        // {
        //     text: t('lecturer-management'),
        //     href: links.teachingManagement
        // }
    ]

    const userTypeLinks = {
        student: studentNavLinks,
        teacher: teacherNavLinks,
        staff: staffNavLinks
    }

    return useMemo(() => {
        if (!ctx.userProfile) return defaultNavLinks

        return userTypeLinks[ctx.userProfile.userType] || defaultNavLinks;
    }, [ctx.userProfile])
}