const images = {
    loginSignupLogo: '/images/small-logo.png',
    logo: '/images/logo.png',
    defaultProfilePic: '/images/default-profile.png',
    addProfilePic: '/images/add-profile.png',
    homeImage:'/images/home_image.png',
    profile:'/images/img_avatar.png',
    course:'/images/demo_course.jpg',
    card_bg:'/images/card_bg.png',
    card_bg_1:'/images/card_bg_1.png',
    card_bg_2:'/images/card_bg_2.png',
    course_detail_header : '/images/course_detail_header.png'
}

export default function useImages() {
    return images
}
