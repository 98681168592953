import React from 'react'
import {icons, availableIcons} from './svg-icons'
import {availableColors} from "../../../styles/Colors";
import css from './icon.module.css'
import clsx from "clsx";
import Link from 'next/link'
import {useWithProps} from "../../with-props";

export function Icon({href, variant, size = 24, color = 'grey700', className}) {
    if (availableIcons.indexOf(variant) < 0) throw Error(`Invalid value of variant: ${variant}. Expected ${availableIcons.join(', ')}`)
    if (availableColors.indexOf(color) < 0) throw Error(`Invalid value of color: ${color}. Expected ${availableColors.join(', ')}`)
    let withProps = useWithProps()

    let classes = clsx({
        [css.linked]: !!href
    }, className)

    let SvgIcon = icons[variant]
    let LinkTag = !!href ?
        ({children, ...props}) => <Link href={href}>
            <a style={{ width: size, height:size }} {...props}>
                {children}
            </a>
        </Link> :
        ({children, ...props}) => <React.Fragment>{withProps(children, props)}</React.Fragment>

    return <LinkTag className={classes}>
        <SvgIcon size={size} color={color}/>
    </LinkTag>
}