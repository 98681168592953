import React from 'react'
import css from './nav-item.module.css'
import clsx from "clsx";
import Link from "next/link";

export function NavItem({children, active = false, href}) {
    return (
        <Link href={href}>
            <a className={css['nav-item']} href={href}>
                <span className={css['nav-text']}>{children}</span>
                <div className={clsx(
                    css['selected-indicator'],
                    {
                        [css['selected-indicator-hidden']]: !active
                    }
                )} />
            </a>
        </Link>
    )
}