import {createContext, useContext, useState} from 'react'

const PageContext = createContext(null);

export function PageContextProvider({children, value}) {
    const state = useState(value)

    return <PageContext.Provider value={state}>
        {children}
    </PageContext.Provider>
}

export function usePageContext() {
    return useContext(PageContext)
}

