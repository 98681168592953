import React from 'react'
import clsx from "clsx";
import css from './style.module.css'
import Text from "../text";
import useImages from "../../../vars/images";
import Link from "next/link";
import {Icon} from "../icon";
import useLinks from "../../../vars/links";
import {useTranslation} from "next-i18next";

export default function ProfileMenu({
    profileImage = null,
    name = '',
    locale = 'en',
    email = '',
    className,
    onMenuItemClick = _ => {},
    userType
}) {
    const {t} = useTranslation('profile-menu')
    const links = useLinks()

    return (
        <div className={clsx(css.root, className)}>
            <div className={css.profile}>
                <ProfileImage profileImage={profileImage} />
                <div className={css.profileNameAndEmail}>
                    <Text locale={locale} variant='bold'>{name}</Text>
                    <Text locale={locale} variant='small'>{email}</Text>
                </div>
            </div>

            {
                userType !== 'staff' && <React.Fragment>
                    <ProfileMenuItem onClick={onMenuItemClick} link={userType === 'student' ? links.userprofile : links.teacherProfile} text={t('profile')} icon='person'/>
                    <ProfileMenuItem onClick={onMenuItemClick} link={links.upcomingLessons} text={t('bookedLessons')} icon='file-list'/>
                    <ProfileMenuItem onClick={onMenuItemClick} link={links.lessonHistories} text={t('lesson')} icon='history'/>
                </React.Fragment>
            }
            <ProfileMenuItem onClick={onMenuItemClick} link={links.logout} text={t('logout')} icon='logout'/>
        </div>
    )
}

export function ProfileImage({profileImage, className, onClick=_ => {}}) {
    const images = useImages()

    return <img onClick={onClick} className={clsx(css.profileImg, className)} src={profileImage || images.defaultProfilePic} alt=""/>
}

function ProfileMenuItem({ onClick, link, text, icon, locale }) {
    return <Link href={link}>
        <a className={css.link} onClick={e => onClick(e, link)}>
            <Icon variant={icon} />
            <Text locale={locale} variant='body' color='grey700'>{text}</Text>
        </a>
    </Link>
}
