import css from './Text.module.css'
import clsx from 'clsx'
import {i18n} from '../../../next-i18next.config'
import {availableColors, colors} from "../../../styles/Colors";
import Link from 'next/link'

const variants = ['h1', 'h2', 'h3', 'subtitle', 'body', 'bold', 'small', 'pretitle']
export const supportedLocales = [...i18n.locales, 'en']

export default function Text({children, locale= 'en', variant, color = 'grey900', href, className,onClick=null}) {
    if (variants.indexOf(variant) < 0) throw Error(`Invalid value of variant: ${variant}. Excepted ${variants.join(', ')}`)
    if (supportedLocales.indexOf(locale) < 0) throw Error(`Invalid value of locale: ${locale}. Excepted ${supportedLocales.join(', ')}`)

    const classes = clsx(
        css.text,
        css[`text-${variant}`],
        {
            [css[`text-${variant}_${locale}`]]: locale !== 'en',
            [css.pointer] : onClick !=null
        },
        className
    )

    const ConditionalLink = !!href ?
        ({children}) =>
            <a href={href} className={classes} style={{
                color: colors[color]
            }}>{children}</a> :
        ({children}) => <span className={classes} onClick={onClick} style={{
            color: colors[color]
        }}>{children}</span>

    return <ConditionalLink>{children}</ConditionalLink>
}
