import React, { forwardRef } from 'react';
import clsx from "clsx";
import css from './Button.module.css';
import Text from "../text";
import Link from "next/link";
import { Icon } from "../icon";

const variants = ['primary', 'secondary', 'link', 'danger']
const sizes = ['m', 'l']

function useConditionalTag(href, disabled, ref) {
    if (!!href) {
        return ({ children, ...props }) => disabled ?
            <span ref={ref} {...props}>{children}</span> :
            (
                <Link href={href}>
                    <a ref={ref} {...props}>{children}</a>
                </Link>
            )
    } else {
        return ({ children, ...props }) => <button type={props.type} ref={ref} {...props}>{children}</button>
    }
}

function Button({ icon = null, onClick = () => { }, type = 'button', disabled = false, children, variant = 'primary', size = 'm', locale = 'en', href = null, className }, ref) {
    if (variants.indexOf(variant) < 0) throw Error(`Invalid variant value: ${variant}. Excepted ${variants.join(', ')}`)
    if (sizes.indexOf(size) < 0) throw Error(`Invalid size value: ${size}. Excepted ${sizes.join(', ')}`)
    const ConditionalTag = useConditionalTag(href, disabled, ref)

    const classes = clsx(
        css.btn,
        css[`btn-${variant}`],
        css[`btn-${size}`],
        {
            [css['btn-disabled']]: disabled,
            [css['pointer']]: !disabled && !!href
        },
        className
    )

    let textColor
    switch (variant) {
        case 'primary': textColor = 'white'; break;
        case 'danger': textColor = 'white'; break;
        case 'secondary': textColor = 'grey700'; break;
        case 'link': textColor = 'blueA700'; break;
    }

    if (disabled) textColor = 'grey400'

    const onClickHandler = (event) => {
        if (disabled) return

        onClick(event)
    }

    return (
        <ConditionalTag
            type={type}
            disabled={disabled}
            onClick={onClickHandler}
            className={classes} >
            {icon ? <Icon variant={icon} color={textColor} /> : <Text locale={locale} variant={variant === 'link' ? 'bold' : 'body'} color={textColor}>
                {children}
            </Text>}
        </ConditionalTag>
    )
}

export default forwardRef(Button)